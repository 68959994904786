import React from "react";

import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";

import Button from "../Button/Button";

import LayoutComponent from "../LayoutComponent/LayoutComponent";

import "./titleDescription.scss";

const TitleDescription = ({
  title,
  description,
  description_2,
  background_color,
  button_text,
  onClick,
  titleMaxWidth,
}) => {
  return (
    <div className="titleDescription-wrapper">
      <LayoutComponent background_color={background_color}>
        <div className="titleDescription-body-content">
          {title && (
            <h2
              className="titleDescription-title"
              style={titleMaxWidth ? { maxWidth: titleMaxWidth } : {}}
            >
              <ReactMarkDown
                rehypePlugins={[rehypeRaw]}
                children={title}
              />
            </h2>
          )}
          {description && (
            <div className="titleDescription-description">
              <ReactMarkDown
                rehypePlugins={[rehypeRaw]}
                children={description}
              />
            </div>
          )}
          {description_2 && (
            <div className="titleDescription-description desc">
              <ReactMarkDown
                rehypePlugins={[rehypeRaw]}
                children={description_2}
              />
            </div>
          )}
          {button_text && (
            <Button
              variant="contained"
              background_color={"#5107FF"}
              text={button_text}
              color={"white"}
              onClick={onClick}
            ></Button>
          )}
        </div>
      </LayoutComponent>
    </div>
  );
};

export default TitleDescription;
