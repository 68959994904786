import React from "react";
import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import CMOButton from "../Button/Button";
import { navigate } from "gatsby-link";
import "./titleDescImg.scss";

const TitleDescImg = ({ title, description, image, imageClass, descriptionClass, btnText, btnLink }) => {
  return (
    <div className="titleDescImg-wrapper">
      <LayoutComponent>
        <div className="body-content">
          {title && <h2 className="title">{title}</h2>}
          <div className="content-image-wrapper" >
            <div className={`image-wrapper ${imageClass}`}>
              <img src={image} alt="kep" />
            </div>
            <div className={`description ${descriptionClass}`}>
                <ReactMarkDown
                  rehypePlugins={[rehypeRaw]}
                  children={description}
                />
                { 
                  btnText 
                  ? <CMOButton onClick={() => { navigate( btnLink ); }} variant="contained" background_color={"#5107ff"} text={btnText} color={"white"}/>
                  : ""
                }
              </div>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};




export default TitleDescImg;
