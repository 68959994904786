import React from "react";
import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";
import logo2 from "../../assets/images/TitleSubtitleDescIcons/gyors.svg";
import logo3 from "../../assets/images/TitleSubtitleDescIcons/koltseghatekony.svg";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import "./titleSubtitleDescIcons.scss";

const TitleSubtitleDescIcons = ({ title, subtitle, description, icons }) => {
  return (
    <div className="titleSubtitleDescIcons-wrapper">
      <LayoutComponent>
        <div className="body-content">
          {title && <h2 className="title">{title}</h2>}
          {subtitle && <p className="subtitle">{subtitle}</p>}
          <div className="content-wrapper">
            {description && (
              <div className="description">
                <ReactMarkDown
                  rehypePlugins={[rehypeRaw]}
                  children={description}
                />
              </div>
            )}
            <div className="icons">
              <div className="icon">
                <img src={logo2} alt="logo1" />
                <div className="">Gyors</div>
              </div>
              <div className="icon">
                <img src={logo3} alt="logo1" />
                <div className="">Költséghatékony</div>
              </div>
            </div>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default TitleSubtitleDescIcons;
