import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import CMOButton from "../../components/Button/Button";
import TitleDescImg from "../../components/TitleDescImg/TitleDescImg";
import VideoImageButton from "../../components/VideoImageButton/VideoImageButton";
import blob from "../../assets/images/VideoImageButton/csaj_blob.png";
import TitleSubtitleDescIcons from "../../components/TitleSubtitleDescIcons/TitleSubtitleDescIcons";
import TitleDescription from "../../components/TitleDescription/TitleDescription";
import FullWidthImageBox from "../../components/FullWidthImageBox/FullWidthImageBox";
import aloldal_penztargep_kep from "../../assets/images/FullWidthImage/többmintszamlazo.jpg";
import BlueCardComponent from "../../components/BlueCardComponent/BlueCardComponent";
import QuoteComponent from "../../components/QuoteComponent/QuoteComponent";

import "./onlinePenztargep.scss";
import { navigate } from "gatsby-link";

const OnlinePenztargep = (props) => {
  const {
    PageHeader,
    SEO,
    BundlePropositions,
    salesDataInOnePlace,
    afr,
    ePenztargep,
    inventoryManagement
  } = props.pageContext.content;

  const location_href = props.location.pathname;

  return (
    <div className="online-penztargep-wrapper">
      <LayoutHeaderFooter
        header_background_color={"#7C81FC"}
        header_secondary_color={"##7C81FC"}
        header_no_fade={true}
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
        SEO_image={SEO.image}
        SEO_location={location_href}
      >
          <div className="page-header-wrapper">
            <div className="decoration" style={{ background: "linear-gradient(180deg, #7C81FC 33.02%, #A6AAF8 104.32%)" }}>
              <div className="subpage">
                <div className="firstComponent-content" style={{ padding: 0 }}>
                  <section className="section-content">
                    <h1>{PageHeader.title}</h1>
                    <div className="description">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={PageHeader.description}
                      />
                    </div>
                  </section>
                  <section className="section-image">
                    <div className="firstComponent-image">
                      <img
                        className="ill-image desktop"
                        src={PageHeader.image ? process.env.GATSBY_CMS_URL + PageHeader.image?.formats.small.url : ""}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div className="bundles-block">
            <div className="content">
              <h2 className="title">{BundlePropositions.Title}</h2>
              <div className="description">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={BundlePropositions.Description}
                />
              </div>

            </div>
            <div className="bundles-section">
              {BundlePropositions.BundlePropositionItem.map((item, index) => (

                <div className="bundle" key={`bundle-${index}`}
                >
                  <div className="bundle-title" style={{ backgroundColor: item.HeaderBackgroundColor, color: item.HeaderTextColor }} >
                    <h3>{item.Name}</h3>
                  </div>
                  <div className="bundle-content" >
                    <div className="bundle-image">
                      <img src={`${process.env.GATSBY_CMS_URL}${item.BundlePropositionImage?.url}`} alt={item.BundlePropositionImage?.alternativeText} style={{ width: "160px", height: "160px" }} />
                    </div>
                    <div className="bundle-description-wrapper">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={item.BundlePropositionDescription}
                      />
                    </div>
                  </div>
                  <div className="bundle-links">
                    <CMOButton
                      onClick={() => navigate(item.ButtonLink)}
                      variant="contained"
                      className="bundle-links"
                      background_color={"#5107ff"}
                      text={item.ButtonText}
                      color={"white"} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        
        { afr &&
          <TitleDescImg
            title={
              afr.title
            }
            description={
              afr.description
            }
            image={ afr.image ? process.env.GATSBY_CMS_URL + afr.image?.url : ""}
            imageClass={ "title-desc-img-img" }
            descriptionClass={"title-desc-img-desc"}
          />
        }

        <TitleSubtitleDescIcons
          title={"Próbáld ki te is"}
          subtitle={
            "a harmadik generációs pénztárgépeinket,\negy forradalmian új konstrukcióban."
          }
          description={
            "A CMO pénztárgépei a piacon egyedülálló módon BÉRELHETŐEK. Ez azt jelenti, hogy nem kell egyszerre egy 6 számjegyű összeget kifizetned érte, hanem előre tervezhető, havidíjas konstrukcióban juthatsz hozzá az új pénztárgépedhez"
          }
        />
        <VideoImageButton image={blob} button_text={"Bérelj pénztárgépet"} />
        { inventoryManagement &&
          <TitleDescImg
            title={inventoryManagement.title}
            description={inventoryManagement.description}
            imageClass={ "title-desc-img-img" }
            image={ inventoryManagement.image ? process.env.GATSBY_CMS_URL + inventoryManagement.image?.url : ""}
            descriptionClass={"title-desc-img-desc"}
          />
        }

      { ePenztargep &&
        <div className="epenztargep-section">
            <TitleDescImg
              title={ePenztargep.Title}
              description={ePenztargep.Description}
              imageClass={ "title-desc-img-img" }
              image={ ePenztargep.Image ? process.env.GATSBY_CMS_URL + ePenztargep.Image?.url : ""}
              descriptionClass={"title-desc-img-desc"}
            />
          </div>
        }
        <QuoteComponent background_color={"#363636"} />
        <TitleDescription title={"Hogyan tudsz online pénztárgépet bérelni?"} />
        <FullWidthImageBox
          image={aloldal_penztargep_kep}
          content={{
            title: "Ingyenes szakértői segítség",
            description:
              "Az alábbi gombra kattintva átirányítunk egy űrlapra, amin keresztül ingyenesen igénybe veheted a CMO egy szakértőjét, aki segít neked kiválasztani az üzletedhez legmegfelelőbb pénztárgépet.",
          }}
          button_text={"Kapcsolatfelvétel"}
          color={"white"}
          right
          onClickUrl={"/form/penztargep"}
        />
        <BlueCardComponent
          title={"Automatikus NAV adatszolgáltatás"}
          description={
            "Az általunk forgalmazott online pénztárgépek maradéktalanul megfelelnek a NAV által támasztott adatszolgáltatási követelményeknek."
          }
        />
      </LayoutHeaderFooter>
    </div>
  );
};

export default OnlinePenztargep;
