import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import kep from "../../assets/images/BlueCardComponent/nav_online_adatszolgaltatas.svg";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import "./blueCardComponent.scss";

const BlueCardComponent = ({
  title,
  subtitle,
  description,
  background_color,
}) => {
  return (
    <div className="blueCardComponent-wrapper">
      <LayoutComponent background_color={background_color}>
        <div className="body-content">
          <div className="card">
            <img src={kep} alt={"logo"} loading="lazy" />
            {title && <p className="title">{title}</p>}
            {subtitle && <p className="subtitle">{subtitle}</p>}
            <div className="description">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={description}
              />
            </div>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default BlueCardComponent;
