import React from 'react';

import stars from '../../assets/images/QuoteComponent/idezet_csillag.svg';

import LayoutComponent from '../LayoutComponent/LayoutComponent';

import './quoteComponent.scss';

const QuoteComponent = ({
  background_color,
}) => {
  return (
    <div className="quoteComponent-wrapper">
      <LayoutComponent background_color={background_color}>
        <div className="body-content">
          <img src={stars} alt="CMO quote" className="stars" loading="lazy" />
          <div className="content">
            <p>
              "Az örökös innováció vágya, és számtalan piac széleskörű ismerete
            </p>
            <p>... Ez az ami megteremtette a CMO-t. Légy részese Te is!”</p>
          </div>
          <div className="signature">
            <p className="name">Várkonyi Gábor</p>
            <p className="titl">CMO Vezérigazgató</p>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default QuoteComponent;
