import React from "react";
import ReactMarkdown from "react-markdown";

import Button from "../Button/Button";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import LayoutComponent from "../LayoutComponent/LayoutComponent";

import "./videoImageButton.scss";
import rehypeRaw from "rehype-raw";

const VideoImageButton = ({ image, video, button_text }) => {
  return (
    <div className="videoImageButton-wrapper">
      <LayoutComponent>
        <div className="body-content">
          <section>
            <img src={image} alt={"berelj_penztargepet"} />
          </section>
          <section className="video-wrapper">
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={video}
            />
            <a href="/online-penztargep/penztargep-berles">
              <Button
                variant="contained"
                background_color={"#5107FF"}
                text={button_text}
                color={"white"}
              ></Button>
            </a>
            <div>vagy</div>
            <a href="/form/penztargep">
              <Button
                variant="contained"
                background_color={"#5107FF"}
                text={"Kérd szakértőnk segítségét a választásban"}
                color={"white"}
              ></Button>
            </a>
            <div className="footNote">
              <VerifiedUserIcon />
              <span>Ingyenes és kötelezettségmentes</span>
            </div>
          </section>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default VideoImageButton;
