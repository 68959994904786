import React from "react";
import ReactMarkDown from "react-markdown";
import Button from "../Button/Button";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import "./fullWidthImageBox.scss";
import rehypeRaw from "rehype-raw";

const FullWidthImageBox = (props) => {
  const { image, content, color, button_text, right, onClickUrl } = props;

  return (
    <div
      className="fullWidthImageBox-wrapper"
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: color,
      }}
      loading="lazy"
    >
      <div className="fullWidthImage-body">
        <div
          className={
            right ? "fullWidthImage-content right" : "fullWidthImage-content"
          }
        >
          <h2 className="titl center">{content.title}</h2>
          <div className="description center">
            <ReactMarkDown
              rehypePlugins={[rehypeRaw]}
              children={content.description}
            />
            {button_text && (
              <a href={onClickUrl}>
                <Button
                  variant="contained"
                  background_color={"#5107FF"}
                  text={button_text}
                  color={"white"}
                ></Button>
              </a>
            )}
            <div className="footNote">
              <VerifiedUserIcon />
              <span>Ingyenes és kötelezettségmentes</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullWidthImageBox;
